import { SelectInput } from "../app/OutlinedInputs";
import useApi from "../../hooks/useApi";
import errorMessageHandler from "../../Api/errorMessageHandler";
import { getApiRoute } from "../../config/routes";
import { useInput, useRecordContext, useNotify } from "react-admin";
import { useState, useEffect, useMemo } from "react";


export const VatInput = ({ source, isEdit = false, creativeVat, isKor = null, isOutsideNl = null, ...props }) => {
    // isKor - creative.kor
    // creativeVat - only for products (null for creative)
    // record - product or creative
    const record = useRecordContext()
    const [vat, setVat] = useState(null)
    const [vats, setVats] = useState([]);
    const { api } = useApi();
    const notify = useNotify();
    const {
      field: { onChange },
    } = useInput({ record, source, ...props })

    useEffect(() => {
      api
        .get(getApiRoute("creative-vats"))
        .then(({ data }) => {
          setVats(data?.data || []);
        })
        .catch((e) => {
          notify(errorMessageHandler(e), "error", {}, false, 100000);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const vatKorDefault = useMemo(() => {
      return vats.find(item => item.kor)
    }, [vats])
    
    const vatDefault = useMemo(() => {
      return vats.find(item => item.default)
    }, [vats])

    const vatOutsideNl = useMemo(() => {
      return vats.find(item => item.outsideNl)
    }, [vats])

    const roles = useMemo(() => {
      return JSON.parse(localStorage.getItem("roles")) || []
    }, []);
    
    const vatsFiltered = useMemo(() => {
      let filtered = vats
      const currentVat = record?.vat || creativeVat

      if (isOutsideNl && !isKor) {
        filtered = [...vats.filter(item => item.outsideNl && item.id !== currentVat?.id), currentVat]
      }

      else if (isOutsideNl === false && !isKor) {
        filtered = [...vats.filter(item => !item.outsideNl && item.id !== currentVat?.id), currentVat]
      }

      return filtered.sort((a, b) => a.id - b.id)
    }, [record, creativeVat, isOutsideNl, isKor, vats])

    useEffect(() => {
      if (vats.length) {
        if (isEdit && record && record.vat) {
          setVat(record.vat.id || record.vat)
        }

        else if (creativeVat) {
          setVat(creativeVat.id || creativeVat)
        }

        else if (isOutsideNl && !isKor) {
          setVat(vatOutsideNl?.id || '')
        }

        else if (isKor) {
          setVat(vatKorDefault?.id || '')
        }

        else {
          setVat(vatDefault?.id || '')
        }
      }
    }, [record, vatDefault, creativeVat, vatKorDefault, isKor, isOutsideNl, vatOutsideNl, vats, isEdit])

    useEffect(() => {
      if (vat !== null) {
        onChange(vat.id || vat)
      }
    }, [vat, onChange])


    const isDisabled = useMemo(() => {
      if (roles?.includes('ROLE_ADMIN')) {
        return false
      }

      if (isKor !== null) {
        return isKor
      }

      return false
    }, [roles, isKor])

    if (!vatsFiltered.length || vat === null) {
      return null
    }

    return (
      <SelectInput
        source="vat"
        format={v => v?.id || v}
        label="VAT"
        choices={vatsFiltered}
        value={vat?.id || vat || ''}
        onChange={(e) => {
          setVat(e.target.value)
        }}
        style={isDisabled ? { pointerEvents: 'none', width: '100%' } : { width: '100%' }}
        {...props}
      /> 
    )
}